<template>
  <v-container class="pa-0 ma-0">
    <div class="message-txt" v-if="progress.exsistMessage">
      {{ progress.exsistMessage }}
    </div>
    <div class="scheduleItems">
      <!-- 集荷予定日 -->
      <span v-if="progress.pickupScheduleDate">
        <strong>集荷予定日：</strong> {{ progress.pickupScheduleDate }}
      </span>
      <!-- 配達予定日 -->
      <span v-if="progress.deliveryScheduleDate">
        <strong>お届け予定日：</strong>{{ progress.deliveryScheduleDate }}
      </span>
      <!-- 小口数 -->
      <span v-if="progress.smallLotsQuantity">
        <strong>小口数：</strong>{{ progress.smallLotsQuantity }}
      </span>
    </div>
    <div class="message-txt worning-message" v-if="progress.message">{{ progress.message }}</div>
    <!-- 進捗バー -->
    <div>
      <!-- max:目盛り数 tick-labels:目盛りに対応するラベル step:変化量 tick-size:目盛りの黒丸の大きさ-->
      <v-slider
        ticks="always"
        track-color="grey"
        v-model="progress.statusNo"
        :max="progress.totalScaleNo"
        thumb-color="transparent"
        step="1"
        tick-size="10"
        @input="$emit('input-slider', progress.statusNo)"
        v-if="progress.pickupScheduleDate"
        :class="[
          'custom-slider',
          {
            'slider-last-thumb':
              this.progress.pickupDeliveryActList &&
              this.progress.scaleLabels &&
              this.progress.pickupDeliveryActList.length === this.progress.scaleLabels.length
                ? true
                : false,
          },
        ]"
      >
      </v-slider>
      <div class="d-flex justify-space-between">
        <div v-for="(status, index) in progress.scaleLabels" :key="index" class="statusLabel">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">{{ status }}</span>
            </template>
            <div
              class="tooltip-content"
              v-if="
                progress.pickupDeliveryActList &&
                progress.pickupDeliveryActList.length > index &&
                progress.pickupDeliveryActList[index].transportOfficeName
              "
            >
              <div class="lineBreak" v-if="progress.pickupDeliveryActList[index].actualDtm">
                {{
                  progress.pickupDeliveryActList && progress.pickupDeliveryActList.length > index
                    ? progress.pickupDeliveryActList[index].actualDtm
                    : ""
                }}
              </div>
              <div>輸送担当営業所</div>
              <div class="detail lineBreak">
                {{
                  progress.pickupDeliveryActList && progress.pickupDeliveryActList.length > index
                    ? progress.pickupDeliveryActList[index].transportOfficeName
                    : ""
                }}
              </div>
              <div>TEL</div>
              <div class="detail">
                {{
                  progress.pickupDeliveryActList && progress.pickupDeliveryActList.length > index
                    ? progress.pickupDeliveryActList[index].transportOfficeTelNum
                    : ""
                }}
              </div>
              <div class="tooltip-arrow"></div>
            </div>
          </v-tooltip>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "ProgressItem",
  components: {},
  props: {
    /** 貨物情報 */
    progress: { type: Object, required: false },
  },
  computed: {},
  methods: {},
};
</script>
<style>
.custom-slider {
  pointer-events: none; /* 全体のクリック操作を無効化 */
  opacity: 0.5;

  .v-slider__tick-label {
    color: rgba(0, 0, 0, 0.54); /* ラベルの色 */
    opacity: 1; /* ラベルの透明度を維持 */
    pointer-events: none; /* ラベルはクリック可能 */
  }
  .v-slider__thumb {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: transparent; /* 背景色を透明にする */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 55px; /* アイコンサイズ */
    color: #000000; /* アイコンの色 */
  }
  .v-slider__thumb::before {
    content: "\F053D"; /* Material Design Iconsのコードポイント */
    font-family: "Material Design Icons"; /* アイコンフォントファミリー */
    font-size: 55px;
    color: #000000; /* アイコンの色 */
    transform: unset;
    opacity: 1;
    background: transparent;
    margin-bottom: 55px;
    top: unset;
  }
  .v-slider__tick-label {
    margin-top: 10px;
    color: black;
    font-weight: bold;
  }
  .v-slider__tick {
    border-radius: 50%;
  }
  .v-slider__tick--filled {
    background-color: #1976d2;
  }
  .v-slider {
    margin-top: 10px;
  }
  .v-messages {
    display: none;
  }
}

.slider-last-thumb {
  .v-slider__thumb::before {
    position: absolute;
    right: 36px;
    left: unset;
  }
}

.scheduleItems span {
  padding-right: 15px;
}
.d-flex {
  display: flex;
}
.justify-space-between {
  justify-content: space-between;
}

.detail {
  padding-left: 12px;
}
.lineBreak {
  margin-bottom: 16px;
}
.v-tooltip__content {
  background-color: transparent !important;
  padding: 0;
}

.tooltip-content {
  position: relative;
  background: rgba(97, 97, 97, 0.9);
  color: white;
  padding: 8px;
  border-radius: 4px;
  width: 160px;
  padding: 10px;
}

.tooltip-arrow {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid rgba(97, 97, 97, 0.9); /* ツールチップと同じ背景色に設定 */
}
.statusLabel:not(:first-child, :last-child) {
  margin-right: 25px;
}
.message-txt {
  padding: 5px 0px;
  font-weight: bold;
}
.worning-message {
  color: red;
}
</style>
